.background-div {
    width: 200px;
    height: 200px;
    border-radius: 15px;
    position: absolute;
    z-index: -1; /* Ensure they are behind other content */
  }
  
  .color-1 { background-color: #F8CFFF; }
  .color-2 { background-color: #A3F2ED; }
  .color-3 { background-color: #AEF2A3; }
  .color-4 { background-color: #FFECA8; }
  .color-5 { background-color: #627BFF; }
  .white-background {
    background-color: rgb(255, 255, 255,.8);
    box-shadow: 0px 0px 120px 100px rgba(255, 255, 255, .8);
    padding: 20px;
    margin: 10px;
    backdrop-filter: blur(5px);
    border-radius: 15px;
  }