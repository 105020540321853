.greenSwitch {
  position: relative;
  height: 28px;
  width: 100px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  background-color: #f4f4f4;
  transition: all .9s ease;
}

.greenSwitch:checked {
  background-color: #f4f4f4;
}

/* White block that toggles */
.greenSwitch::before {
  position: absolute;
  content: "";
  display: block;
  height: 20px;
  width: 47px;
  cursor: pointer;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);

  box-shadow: 1px 1px 5px 1px rgb(0,0,155,.07);
  opacity: 0;
  transition: opacity 0.2s ease;
}

/* Left position white block */
.greenSwitch::before {
  left: 4px;
  top: 4px;
  opacity: 1;
}

/* Right position white block */
.greenSwitch:checked::before {
  left: calc(100% - 51px);
  top: 4px;
  opacity: 1;
}

/* NO text */
.greenSwitch::after {
  content: "Off";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
  font-size: 12px;
  font-family: montserrat, sans-serif;
  font-weight: 600;
  color: #333333;
}

/* YES text */
.greenSwitch + span {
  content: "On";
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #999999;
  pointer-events: none;
}

/* Text color states */
.greenSwitch:checked::after {
  color: #999999;
}

.greenSwitch:checked + span {
  color: #333333;
}

/* Hover effects */
.greenSwitch:hover::before {
  box-shadow: 1px 1px 5px 1px rgb(0,0,155,.07);
}

.greenSwitch:checked:hover::before {
  box-shadow: 1px 1px 5px 1px rgb(0,0,155,.07);
}