.loader {
  display: block;
  position: relative;
  height: 20px;
  width: 140px;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(#f4f4f4 20px, transparent 0), 
                    linear-gradient(#f4f4f4 20px, transparent 0), 
                    linear-gradient(#f4f4f4 20px, transparent 0), 
                    linear-gradient(#f4f4f4 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: 0 0, 40px 0, 80px 0, 120px 0;
  animation: pgfill 2.5s steps(1) infinite;
}

@keyframes pgfill {
  0% {
      background-image: linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#FCCA18 20px, rgba(252, 202, 24, 0.3)),
                       linear-gradient(#00CD09 20px, rgba(0, 205, 9, 0.1));
  }
  20% {
      background-image: linear-gradient(#020CFF 20px, rgba(2, 12, 255, 1)),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#FCCA18 20px, rgba(252, 202, 24, 0.1));
  }
  40% {
      background-image: linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#E441FF 20px, rgba(228, 65, 255, 0.3)),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#020CFF 20px, rgba(2, 12, 255, 0.1));
  }
  60% {
      background-image: linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#00CD09 20px, rgba(0, 205, 9, 0.3)),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#E441FF 20px, rgba(228, 65, 255, 0.1));
  }
  80% {
      background-image: linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#FCCA18 20px, rgba(252, 202, 24, 0.3)),
                       linear-gradient(#00CD09 20px, rgba(0, 205, 9, 0.1));
  }
  100% {
      background-image: linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#f4f4f4 20px, transparent 0),
                       linear-gradient(#FCCA18 20px, rgba(252, 202, 24, 0.3));
  }
}